html {
    scroll-behavior: smooth;
}

.header-image {
    background-image: url('../images/hero_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.button-bg {
    background: linear-gradient(97.24deg, #ff0009 -5.21%, #0036f3 102.43%);
}

.tokenomics_bg {
    background-image: url('../images/tokenomics_bg.png');
    background-repeat: no-repeat;
    background-position: center;
}

.bg_image {
    background-image: url(../images/square_image.png);
    aspect-ratio: 12/6;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}